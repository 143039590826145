.wrapper-home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgb(33, 33, 33);
    width: 100%;
    height: 100vh;
    opacity: 1;
    transition: 0.3s;
}

.wrapper-home-white {
    opacity: 0;
    transition: 0.3s;
}

.container-home {
    display: flex;
}

.user-info-home {
    width: 61.8%;
    height: 96vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.user-info-element {
    color: rgb(143, 143, 143);
    font-size: 25px;
    width: 80%;
    transition: 0.3s;
    font-weight: 400;
}

.menu {
    width: 38.2%;
    height: 96vh;
    display: flex;
    flex-direction: column;
}

.menu-element {
    width: 100%;
    height: 33.33%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu-element:hover {
    box-shadow: inset 0 0 5px grey;
}

.page-name {
    color: rgb(143, 143, 143);
    font-size: 20px;
    font-weight: 400;
    transition: 0.3s;
}

.page-name:hover {
    text-decoration: underline;
}

.page-link {
    display: contents;
}

.profile-picture-home {
    display: none;
}

@media screen and (max-width: 765px) {
    .user-info-element {
        font-size: 20px;
        transition: 0.3s;
    }
    .page-name {
        font-size: 20px;
        transition: 0.3s;
    }
}