body {
    background-color: black;
}

.wrapper-about-me {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 1;
    transition: 0.3s;
    background-color: rgb(33, 33, 33);
}

.wrapper-black-about-me {
    opacity: 0;
    transition: 0.3s;
}

.about-me-title {
    position: absolute;
    float: right;
    top: 0px;
    left: 40px;
    height: auto;
    font-weight: 400;
    font-size: 23px;
    color: rgb(143, 143, 143);
}

.about-user-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 64%;
    height: 60vh;
    margin-top: 10vh;
    margin-bottom: 20vh;
}

.about-user-info-resume {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80vw;
    margin-top: 10vh;
    margin-bottom: 10vh;
}

.about-user-info:last-child {
}

.about-user-info-picture {
    /* flex: 1; */
    /* padding: 5px; */
    /* border: 1px solid black; */
    flex-shrink: 5;
    /* flex-shrink: 5; */
    /* background-color: aqua; */
    width: 35vw;
    /* height: 100%; */
    /* background-color: aqua; */
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* align-content: center; */
}

.about-user-info-text-container {
    /* flex-shrink: 1; */
    /* flex-grow: 5; */
    flex-shrink: 1;
    height: 50%;
    /* flex: 1; */
    /* background-color: aqua; */
    margin-left: 10px;
    margin-right: 10px;
    /* width: 10vw; */
    /* height: 100%; */
    display: flex;
    /* align-items: center; */
    /* justify-content: center; */
    /* background-color: blue; */
}

.about-user-info-text {
    /* flex: 1; */
    /* flex-shrink: 5; */
    /* background-color: aquamarine; */
    /* width: 60vw; */
    /* height: 100%; */
    padding: 5px;
    align-items: top;
    /* text-align: center; */
    /* justify-content:; */
    /* background-color: blue; */
}

/*////////////////////////////////////////////////////////////*/

.navbar {
    position: absolute;
    height: 200px;
    /* border: 1px solid black; */
    width: 70px;
    right: 0px;
    overflow: hidden;
}

.burger {
    transition: 0.3s;
    float: right;
    margin-right: 18px;
    margin-top: 20px;
    cursor: pointer;
    /* border: 0.5px solid black; */
}

.burger-slice {
    transition: 0.3s;
    width: 20px;
    height: 2px;
    /* background: black; */
    background: grey;
    margin-top: 2px;
}

.burger-slice-open {
    /* transform: translateX(10px); */
    width: 20px;
    height: 2px;
    /* background: black; */
    background: grey;
    margin-top: 2px;
    opacity: 0;
    transition: 0.3s;
}

.burger-slice-open:first-child {
    /* margin-top: 0px; */
    opacity: 1;
    transition: 0.3s;
    transform: rotate(45deg) translateY(5.5px);
    display: block;
}

.burger-slice-open:last-child {
    /* margin-top: 0px; */
    opacity: 1;
    transition: 0.3s;
    transform: rotate(-45deg) translateY(-5.5px);
    display: block;
}

ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
}

a {
    text-decoration: none;
}

.page-title {
    /* color: black; */
    color: grey;
    font-size: small;
    /* border: 1px solid black; */
    /* font-weight: 400; */
}

.page-title:hover {
    text-decoration: underline;
}

/*////////////////////////////////////////////////////////////*/

.bo {}

.profile-picture {
    height: 90%;
    border: 1px solid black;
    padding: 3px;
    margin-left: 5px;
    margin-right: 5px;
}

.about-me-user-info-text-paragraph {
    text-align: justify;
    margin-left: 10px;
    font-size: 14px;
    color: grey;
}

/* @media screen and (max-width: 768px) { */

.burger {
    display: block;
}

/* .burger:focus {
    opacity: 0.3;
} */

ul {
    list-style-type: none;
    /* background-color: white; */
    padding-left: 0px;
}

a {
    text-decoration: none;
    /* background-color: aqua; */
}

.resume-contact-button-section {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    height: 50px;
}

.resume-link, .contact-me-link {
    color: grey;
    height: fit-content;
    border: 1px solid grey;
    padding: 3px;
    transition: 0.3s;
}

.resume-link:hover, .contact-me-link:hover {
    color: rgb(33, 33, 33);
    height: fit-content;
    background-color: grey;
    border: 1px solid grey;
    padding: 3px;
    transition: 0.3s;
}

::-webkit-scrollbar {
    width: 10px;
    background-color: rgb(72, 72, 72);
}

::-webkit-scrollbar-thumb {
    background-color: rgb(24, 24, 24);
}

.technical-skills {
    width: 80vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
}

.technical-skills-title, .work-experience-title, .personal-projects-title, .university-projects-title, .education-title {
    color: rgb(177, 177, 177);
}

.technical-skills-content {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.technical-skills-section {
    width: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.technical-skills-section-list {
    /* list-style-type: "- "; */
    align-items: center;
}

.technical-skills-section-list-title {
    font-size: 14px;
    font-weight: 600;
    color: rgb(163, 163, 163);
    text-align: center;
}

.work-experience, .personal-projects, .university-projects, .education {
    width: 80vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 90%;
    margin-bottom: 40px;
}

.work-experience-content, .personal-projects-content, .university-projects-content, .education-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.technical-skills-content, .work-experience-content-entry, .personal-projects-content-entry, .university-projects-content-entry {
    width: 80%;
}

.education-content-entry {
    width: 80%;
}

.technical-skills-content, .work-experience-content-entry:first-child, .personal-projects-content-entry:first-child, .university-projects-content-entry:first-child, .education-content-entry:first-child {
    border-top: 2px solid rgb(85, 85, 85);
}

.work-experience-content-entry-title, .personal-projects-content-entry-title, .university-projects-content-entry-title, .personal-projects-content-entry-github-link, .university-projects-content-entry-class-title, .education-content-entry-title {
    margin-bottom: 10px;
    width: fit-content;
    display: inline-block;
    color: rgb(170, 170, 170);
}

.personal-projects-content-entry-github-link:hover {
    color: white;
}

.university-projects-content-entry-class-title, .education-content-entry-class-title {
    color: grey;
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: 400;
}

.work-experience-content-entry-date, .personal-projects-content-entry-date, .university-projects-content-entry-date, .education-content-entry-date {
    float: right;
    margin-right: 20px;
    font-weight: 300;
    color: grey;
}

.work-experience-content-entry-list, .personal-projects-content-entry-list, .university-projects-content-entry-list, .education-content-entry-list {
    list-style-type: "- ";
    width: 100%;
}

.technical-skills-section-list-element, .work-experience-content-entry-list-element, .personal-projects-content-entry-list-element, .university-projects-content-entry-list-element, .education-content-entry-list-element {
    margin-left: 20px;
    margin-right: 10px;
    text-align: justify;
    font-size: 13px;
    color: rgb(163, 163, 163);
    padding-bottom: 5px;
}

.university-projects-content-entry-tools, .personal-projects-content-entry-tools {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    min-height: 6vh;
    margin-bottom: 10px;
    margin-right: 10px;
}

.university-projects-content-entry-tools-image, .personal-projects-content-entry-tools-image {
    height: 3vh;
}

.university-projects-content-entry-tools-title, .personal-projects-content-entry-tools-title {
    float: left;
    margin-left: 20px;
    margin-right: 10px;
    text-align: justify;
    font-size: 1.5vh;
    color: grey;
    padding-bottom: 5px;
}


strong {
    color: rgb(202, 202, 202);
}

.education-content-entry-title-date-section {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

@media screen and (max-width: 850px) {
    .about-user-info {
        flex-direction: column;
        transition: 0.3s;
    }
    .about-user-info-picture {
        width: 100%;
        height: 35%;
        transition: 0.3s;
    }
    img {
        height: 90%;
        transition: 0.3s;
    }
    .resume-link, .contact-me-link {
        font-size: 12px;
        margin-top: 10px;
        transition: 0.3s;
    }
    .about-me-user-info-text-paragraph {
        font-size: 13px;
        transition: 0.3s;
    }
    .work-experience-content-entry-date, .personal-projects-content-entry-date, .university-projects-content-entry-date, .education-content-entry-date {
        float: none;
        margin-right: 0px;
        font-size: 12px;
        transition: 0.3s;
    }
    .university-projects-content-entry-class-title, .education-content-entry-class-title {
        font-size: 12px;
        transition: 0.3s;
    }
}

.about-me-user-info-text-paragraph-link {
    text-decoration: underline;
    color: grey;
}

.dropdown-resume {
    display: none;
}

.dropdown-resume:hover {
    display: block;
}

.resume-link:hover .dropdown-resume {
    display: block;
}

.resume-link-option {
    color: rgb(33, 33, 33);
    display: block;
    text-align: center;
}
.resume-link-option:hover {
    color: white;
}